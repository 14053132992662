.App {
  text-align: center;
  position: relative;
}

#root, .App {
  min-height: 100vh;
}

.form-field {
  /** margin-top: 25px; */
  margin-bottom: 50px;
}

.address-container {
  margin-top: 25px;
  margin-bottom: 25px;
  margin-left: 30%;
  margin-right: 30%
}

.red {
  color: red;
}

.footer {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
}

@media (prefers-reduced-motion: no-preference) {
  .fa-spinner {
    animation: spin-animation infinite 1s linear;
  }
}

@keyframes spin-animation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}