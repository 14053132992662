body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "Chomsky";
  src: url('./fonts/chomsky/Chomsky.woff') format('woff'),
       url('./fonts/chomsky/Chomsky.woff2') format('woff2'),
       url('./fonts/chomsky/Chomsky.otf') format('otf');
  font-weight: normal;
  font-display: swap;
}

.chomsky {
  font-family: "Chomsky";
}

h1.chomsky {
  font-size: 3.5rem;
}

.tool-page {
  padding-bottom: 100px;
}

.tool-how {
  margin-top: 10px;
  margin-bottom: 10px;
}

.map-header {
  background-color: #38fa;
}

.col-lg-2-5 {
  flex: 0 0 auto;
  width: 21.35%
}

.max-vh-70 {
  max-height: 70vh;
}

.cursor-pointer {
  cursor: pointer;
}

@keyframes spinner {
  0% {
    transform: translate3d(-50%, -50%, 0) rotate(0deg);
  }
  100% {
    transform: translate3d(-50%, -50%, 0) rotate(360deg);
  }
}

/*
.spinner::before {
  animation: 1.5s linear infinite spinner;
  animation-play-state: inherit;
  border: solid 5px #cfd0d1;
  border-bottom-color: #1c87c9;
  border-radius: 50%;
  content: "";
  height: 100%;
  width: 100%;
  margin-top: 50px;
  position: absolute;
  transform: translate3d(-50%, -50%, 0);
  will-change: transform;
}
*/

.spinner {
  animation: 1.5s linear infinite spinner;
  animation-play-state: inherit;
  border: solid 5px #cfd0d1;
  border-bottom-color: #1c87c9;
  border-radius: 50%;
  height: 100%;
  width: 100%;
  margin-top: 50px;
  position: absolute;
  transform: translate3d(-50%, -50%, 0);
  will-change: transform;
}

table.black-border, table.black-border > tr, table.black-border > tr > td, table.black-border > tr > th {
  border: 2px solid black;
}

.no-border {
  border: 0px;
}

th {
  background: lightgrey;
}

.highlight-problem-light {
  background: #cc8e84;
}

.highlight-problem-dark {
  background: #e55039;
}

.highlight-ok {
  background: lightblue;
}

.mymodal-backdrop {
  display: block;
  position: fixed;
  z-index: 999;
  width: 100%;
  height: 100%;
  overflow: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0,0,0,0.3);
}

.mymodal {
  background-color: #fff;
  margin: 5% auto;
  border-radius: 5px;
  width: 60%;
  padding: 30px;
  position: relative;
  text-align: center;
}

.mymodal-close {
  background: transparent;
  border: none;
  position: absolute;
  right: 0;
  top: 0;
  float: right;
  color: rgb(0,0,0,0.5);
}

.bg-chimera {
  background-color: #442b2b;
}

.searchbar {
  background-color: rgba(255, 255, 255, 0.2);
  border-color: rgba(255, 255, 255, 0.2);
  color: inherit;
  max-width: fit-content;
}

.searchbar:focus {
  background-color: inherit;
  color: white;
  border-top: 0px;
  border-right: 0px;
  border-left: 0px;
  border-bottom: 2px solid rgba(255, 255, 255, 0.2);
  border-radius: 0px;
  outline: 0px !important;
  box-shadow: none !important;
}

.section-outline {
  border: 2px solid #d3d3d3;
  border-radius: 5px;
  padding: 10px;
}

.logo-dull {
  filter: saturate(0%) brightness(50%);
}

.w-fit {
  width: fit-content;
}

.h-fit {
  height: fit-content;
}

.monospace {
  font-family: Consolas, 'Courier New', monospace;
}

.hover-blue:hover {
  color: rgb(98, 172, 218) !important;
}

.translate-close {
  transform: translate(-20%,-80%) !important;
}

.fa-magnifying-glass::before {
  content: "\f002";
}

.fa-circle-info::before {
  content: "\f05a";
}

.fa-triangle-exclamation::before {
  content: "\f071";
}

.fa-up-right-from-square::before {
  content: "\f35d";
}

.fa-note-sticky::before {
  content: "\f249";
}

.fa-pencil::before {
  content: "\f303";
}

.fa-circle-check::before {
  content: "\f058";
}

.fa-circle-xmark::before {
  content: "\f057";
}

.fa-circle-question::before {
  content: "\f059";
}

.fa-floppy-disk::before {
  content: "\f0c7";
}

.fa-house::before {
  content: "\f015";
}

.fa-screwdriver-wrench::before {
  content: "\f7d9";
}

.filter-white {
  filter: invert(100%) sepia(100%) saturate(1%) hue-rotate(39deg) brightness(103%) contrast(101%);
  width: 1.2em;
}

/* Tooltip container */
.tooltip-container {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

/* Tooltip text */
.tooltip-container .tooltip-text, .tooltip-container .tooltip-text-bottom{
  visibility: hidden;
  width: 240px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  position: absolute;
  z-index: 999 !important;
  left: 50%;
  margin-left: -120px; /* Use half of the width to center the tooltip */
}

.tooltip-container .tooltip-text {
  bottom: 100%;
}

.tooltip-container .tooltip-text-bottom {
  top: 100%;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip-container:hover .tooltip-text, .tooltip-container:hover .tooltip-text-bottom {
  visibility: visible;
}

.tooltip-container .tooltip-text::after, .tooltip-container .tooltip-text-bottom::after {
  content: " ";
  position: absolute;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
}

.tooltip-container .tooltip-text::after {
  top: 100%; /* At the bottom of the tooltip */
  border-color: black transparent transparent transparent;
}

.tooltip-container .tooltip-text-bottom::after {
  bottom: 100%; /* At the top of the tooltip */
  border-color: transparent transparent black transparent;
}

/** Autocomplete **/
.suggestions {
  border: 1px solid #999;
  border-top-width: 0;
  list-style: none;
  margin-top: 0;
  max-height: 143px;
  overflow-y: auto;
  padding-left: 0;
  width: calc(300px + 1rem);

  position: absolute;
  z-index: 9999;
}

.suggestions li {
  padding: 0.5rem;
  background-color: #e0e0e0;
}

.suggestion-active,
.suggestions li:hover {
  background-color: #008f68 !important;
  color: #fae042;
  cursor: pointer;
  font-weight: 700;
}

.suggestions li:not(:last-of-type) {
  border-bottom: 1px solid #999;
}

label.autocomplete {
  position: relative;
}
/** End of Autocomplete **/